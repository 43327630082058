import styles from "./Logo.module.css";

export default function Logo() {

  return <div className={styles.logo_wrapper}>
    <pre className={styles.logo}>
    {`__________            .___.__         ___________                             
\\______   \\____     __| _/|__|  ____  \\__    ___/ ____ __  _  __  ____ _______ 
|       _/\\__  \\   / __ | |  | /  _ \\    |    |  /  _ \\\\ \\/ \\/ /_/ __ \\\\_  __ \\
|    |   \\ / __ \\_/ /_/ | |  |(  <_> )   |    | (  <_> )\\     / \\  ___/ |  | \\/
|____|_  /(____  /\\____ | |__| \\____/    |____|  \\____/  \\/\\_/   \\___  >|__|   
       \\/      \\/      \\/                                            \\/        `}
    </pre>
  </div>
}
